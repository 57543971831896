<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{
              $t("message.new_m", {
                m: $t("message.position_p"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form ref="form" status-icon :model="form" :rules="rules">
                <div class="position__flex">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.name") }}
                  </span>
                <el-form-item
                  prop="name"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.name"
                    v-model="form.name"
                    :placeholder="$t('message.name')"
                    :class="mode ? 'input__day' : 'input__night'"
                  ></crm-input>
                </el-form-item>
                </div>
                <div class="position__flex">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="position__title">{{ $t('message.requirements') }}</span>
                  <el-form-item
                    prop="requirement_ids"
                    class="mt-1"
                  >
                    <select-requirement
                      :collapseTags="true"
                      :size="'medium'"
                      :id="form.requirement_ids"
                      v-model="form.requirement_ids"
                      :multiple="true"
                    >
                    </select-requirement>
                  </el-form-item>
                </div>
                <div class="position__flex">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="position__title">{{ $t('message.company') }}</span>
                  <el-form-item
                    prop="company_ids"
                  >
                    <select-company
                      :size="'medium'"
                      :id="form.company_ids"
                      v-model="form.company_ids"
                      :collapseTags="true"
                      :multiple="true"
                    >
                    </select-company>
                  </el-form-item>
                </div>
                
              </el-form>
            </el-col>
            <!-- end col -->
          </el-row>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import selectRequirement from "@/components/filters/inventory/select-requirement";
import selectCompany from "@/components/filters/inventory/select-company";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawer],
  name: "PositionController",
  data() {
    return {};
  },
  created() {},
  components: { selectRequirement, selectCompany },
  computed: {
    ...mapGetters({
      rules: "position/rules",
      model: "position/model",
      columns: "position/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "position/store",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.requirement {
  .el-select__tags {
    transform: none;
  }
}
.position__title {
  float: left;
  font-size: 14px;
  color: #606266;
  padding: 0 12px 0 0;
}
.position__flex {
  display: flex;
  flex-direction: column;
}
</style>
